import * as types from './mutation-types'

export default {
  [types.SET_ENTITIES](state, payload) {
    if (payload.parentId === null) {
      state.rootEntities = payload.categories
    } else {
      const tmp = {}
      tmp[payload.parentId] = payload.categories
      state.entitiesByParentId = { ...state.entitiesByParentId, ...tmp }
    }
  },
  [types.SET_FULL_ENTITIES](state, payload) {
    const tmpRootEntities = []
    const tmpEntitiesByParentId = {}
    const tmpEntitiesById = {}
    payload.forEach((item) => {
      if (item.parent_id) {
        // eslint-disable-next-line no-prototype-builtins
        if (!tmpEntitiesByParentId.hasOwnProperty(item.parent_id)) {
          tmpEntitiesByParentId[item.parent_id] = []
        }
        tmpEntitiesByParentId[item.parent_id].push(item)
      } else {
        tmpRootEntities.push(item)
      }
      tmpEntitiesById[item.id] = item
    })
    state.rootEntities = tmpRootEntities
    state.entitiesByParentId = tmpEntitiesByParentId
    state.entitiesById = tmpEntitiesById
  },
}
/**/
