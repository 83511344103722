import * as types from './mutation-types'
import { api, getBaseUrlNewApi } from '~/config/api'
import { ERROR } from '~/ZenOne-StoryBook/helpers/ui'

export default {
  async fetchEntities({ commit, state }, payload) {
    try {
      const params = {
        parent_id: payload.parentId,
      }
      const config = {
        url: `${getBaseUrlNewApi(process.env.NODE_ENV)}/${api.CATEGORIES}`,
        method: 'GET',
        params,
      }
      const response = await this.$axios(config)
      commit(types.SET_ENTITIES, {
        parentId: payload.parentId,
        categories: response.data.data,
      })
    } catch (e) {
      const error = `Fetch category entities error: ${
        e?.response?.data?.errors?.[0]?.detail ?? e
      }`
      commit(
        'ui/SHOW_ALERT',
        {
          type: ERROR,
          content: error,
          show: true,
        },
        { root: true }
      )
    }
  },
  async fetchAllEntities({ commit }) {
    try {
      const params = {
        full: true,
      }
      const config = {
        url: `${getBaseUrlNewApi(process.env.NODE_ENV)}/${api.CATEGORIES}`,
        method: 'GET',
        params,
      }
      const response = await this.$axios(config)
      commit(types.SET_FULL_ENTITIES, response.data.data)
    } catch (e) {
      const error = `Fetch all category entities error: ${
        e?.response?.data?.errors?.[0]?.detail ?? e
      }`
      commit(
        'ui/SHOW_ALERT',
        {
          type: ERROR,
          content: error,
          show: true,
        },
        { root: true }
      )
    }
  },
  async storeEntity({ commit, state, dispatch }, payload) {
    try {
      const data = {
        parent_id: payload.parentId,
        name: payload.name,
        sds_required: payload.sdsRequired,
      }
      let method = ''
      let url = ''
      if (payload.id) {
        method = 'PATCH'
        url = `${getBaseUrlNewApi(process.env.NODE_ENV)}/${api.CATEGORIES}/${
          payload.id
        }`
      } else {
        method = 'POST'
        url = `${getBaseUrlNewApi(process.env.NODE_ENV)}/${api.CATEGORIES}`
      }
      const config = {
        url,
        method,
        data,
      }
      await this.$axios(config)
      await dispatch('fetchEntities', payload)
    } catch (e) {
      const error = `Store category: ${
        e?.response?.data?.errors?.[0]?.detail ?? e
      }`
      console.error(error)
      commit(
        'ui/SHOW_ALERT',
        {
          type: ERROR,
          content: error,
          show: true,
        },
        { root: true }
      )
    }
  },
  async removeEntity({ commit, state, dispatch }, payload) {
    try {
      payload.parentId = payload.parent_id
      const config = {
        url: `${getBaseUrlNewApi(process.env.NODE_ENV)}/${api.CATEGORIES}/${
          payload.id
        }`,
        method: 'DELETE',
      }
      await this.$axios(config)
      await dispatch('fetchEntities', payload)
    } catch (e) {
      const error = `Remove category entity error: ${
        e?.response?.data?.errors?.[0]?.detail ?? e
      }`
      commit(
        'ui/SHOW_ALERT',
        {
          type: ERROR,
          content: error,
          show: true,
        },
        { root: true }
      )
    }
  },
  async moveOrMergeEntities({ commit, state, dispatch }, payload) {
    try {
      const data = {
        source: payload.source.map((item) => item.id),
        destination: payload.destination.id,
      }
      const url =
        payload.type === 'move'
          ? `${getBaseUrlNewApi(process.env.NODE_ENV)}/${api.CATEGORIES}/move`
          : `${getBaseUrlNewApi(process.env.NODE_ENV)}/${api.CATEGORIES}/merge`

      const config = {
        url,
        method: 'POST',
        data,
      }
      await this.$axios(config)
      await dispatch('fetchAllEntities')
    } catch (e) {
      const error = `Move category entity error: ${
        e?.response?.data?.errors?.[0]?.detail ?? e
      }`
      commit(
        'ui/SHOW_ALERT',
        {
          type: ERROR,
          content: error,
          show: true,
        },
        { root: true }
      )
    }
  },
}
