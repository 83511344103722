const manufacturersLibrary = {
  state: {
    selectedProductsId: [],
    selectedProductsModelId: [],
  },

  mutations: {
    setSelectedProductsIdManufacturersLibrary: (state, value) => {
      state.selectedProductsId = value
    },
    setSelectedProductsModelIdManufacturersLibrary: (state, value) => {
      state.selectedProductsModelId = value
    },
  },

  actions: {},

  getters: {
    getSelectedProductsIdManufacturersLibrary: (
      state // todo rename
    ) => state.selectedProductsId,
    getSelectedProductsModelIdManufacturersLibrary: (
      state // todo rename
    ) => state.selectedProductsModelId,
  },

  namespaced: true,
}

export default manufacturersLibrary
