import Vue from 'vue'

const copyToClipboard = ({ text = '', store = {} }) => {
  navigator.clipboard.writeText(text).then(
    () => {
      if (store) {
        store.commit('ui/SHOW_SNACKBAR', {
          text: 'Successfully copied into the clipboard',
        })
      }
    },
    () => {}
  )
}

Vue.directive('copy', {
  bind(el, binding, vnode) {
    const store = vnode.context.$store
    const eventType = binding.arg || 'click'
    const handler = copyToClipboard.bind(null, { text: binding.value, store })

    el.__copyHandler__ = handler
    el.addEventListener(eventType, handler)
  },
  update(el, binding, vnode) {
    const store = vnode.context.$store
    const eventType = binding.arg || 'click'

    el.removeEventListener(eventType, el.__copyHandler__)

    const handler = copyToClipboard.bind(null, { text: binding.value, store })

    el.__copyHandler__ = handler
    el.addEventListener(eventType, handler)
  },
  unbind(el, binding) {
    const eventType = binding.arg || 'click'
    el.removeEventListener(eventType, el.__copyHandler__)
    delete el.__copyHandler__
  },
})
