//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import IconChat from '../SvgIcons/IconChat'
import IconConsumers from '../SvgIcons/IconConsumers'
import IconOrders from '../SvgIcons/IconOrders'
import IconOrdersLarge from '../SvgIcons/IconOrdersLarge'
import IconPartners from '../SvgIcons/IconPartners'
import IconPartnersLarge from '../SvgIcons/IconPartnersLarge'
import IconSettings from '../SvgIcons/IconSettings'
import IconSettingsLarge from '../SvgIcons/IconSettingsLarge'
import IconTasks from '../SvgIcons/IconTasks'
import IconDashboard from '../SvgIcons/IconDashboard'
import IconDashboardLarge from '../SvgIcons/IconDashboardLarge'
import IconCatalog from '../SvgIcons/IconCatalog'
import IconCatalogLarge from '../SvgIcons/IconCatalogLarge'
import { DEFAULT, LARGE } from '../../helpers/icons'
import { isObjectNotUndefinedHasProps } from '../../helpers/object'

export default {
  name: 'LeftMenuItem',
  components: {
    IconCatalog,
    IconCatalogLarge,
    IconDashboard,
    IconDashboardLarge,
    IconChat,
    IconConsumers,
    IconOrders,
    IconOrdersLarge,
    IconPartners,
    IconPartnersLarge,
    IconSettings,
    IconSettingsLarge,
    IconTasks,
  },
  props: {
    item: {
      type: Object,
      default() {
        return {}
      },
    },
    largeIcon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      DEFAULT,
      LARGE,
      linkIsHovered: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
      hasUncompletedTasks: 'user/hasUncompletedTasks',
    }),
    isIndex() {
      return this.item.route === '/'
    },
    isActive() {
      const routeName = this.$route?.name ?? ''
      const alias = this.item.alias
      // todo to constants
      if (routeName === 'settings-catalog') {
        if (alias === 'settings') return true
        else if (alias === 'catalog') return false
      }
      if (alias === 'dashboard' && routeName === 'index') return true
      return (
        routeName === alias || (routeName?.length && routeName.includes(alias))
      )
    },
    color() {
      const { isActive, linkIsHovered } = this
      if (linkIsHovered && !isActive) return '#2E7D32'
      return '#fff'
    },
    showRedCircle() {
      return (
        isObjectNotUndefinedHasProps(this.user) &&
        this.hasUncompletedTasks &&
        this.item.alias === 'tasks'
      )
    },
  },
  methods: {
    hover(e) {
      const { isActive, linkIsHovered } = this
      if (!isActive && !linkIsHovered) {
        this.linkIsHovered = true
        e.target.addEventListener(
          'mouseout',
          () => (this.linkIsHovered = false),
          false
        )
      }
    },
  },
}
