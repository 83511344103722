import { isHavingProps } from '~/ZenOne-StoryBook/helpers/object'
import { getLocalStorage } from '~/ZenOne-StoryBook/helpers/localStorage'
import {
  RESPONSE_STATUS_UNAUTHORIZED,
  RESPONSE_STATUS_UNPROCESSABLE_CONTENT,
} from '~/ZenOne-StoryBook/helpers/response'
import { ERROR } from '~/ZenOne-StoryBook/helpers/ui'

const isRefresh = (url) => {
  const endpoint = url.slice(url.lastIndexOf('/') + 1)
  return endpoint && endpoint === 'refresh'
}

export default function (context) {
  const { $axios, store, $jsonApi, $sentry } = context
  $axios.interceptors.request.use(
    (config) => {
      const { $auth } = context
      const expired = $auth.strategy.token.status().expired()
      if (!expired) return config
      const { url = null } = config
      if (url && isRefresh(url)) {
        let user = store.getters['user/user']
        if (!user || !isHavingProps(user)) {
          user = getLocalStorage('user')
          if (!user || !isHavingProps(user)) return config
        }
        const { email = null, refreshToken = null } = user
        if (!email || !refreshToken) return config
        const accessToken = $auth.strategy.token.get()
        config.headers.Authorization = ''
        config.data = {
          email,
          accessToken,
          refreshToken,
        }
      }
      return config
    },
    (error) => Promise.reject(error)
  )
  $axios.interceptors.response.use(
    (response) => {
      const {
        config: { url = null },
      } = response
      if (url && isRefresh(url)) {
        const {
          data: { AccessToken },
        } = response
        if (AccessToken) {
          const { $auth } = context
          if ($auth && isHavingProps($auth)) {
            $auth.setUserToken(AccessToken)
            $jsonApi.headers = {
              Accept: 'application/vnd.api+json',
              'Content-Type': 'application/vnd.api+json',
              Authorization: $auth.strategy.token.get(),
            }
          }
        }
      }
      return response
    },
    (error) => {
      $sentry.captureException(error)
      if (error.response?.status === RESPONSE_STATUS_UNPROCESSABLE_CONTENT) {
        store.commit('ui/SHOW_ALERT', {
          type: ERROR,
          content: error.response.data?.errors?.[0]?.detail ?? 'Error text',
          show: true,
        })
      }
      return Promise.reject(error)
    }
  )

  $jsonApi.interceptors.request.use(
    async (config) => {
      const { $auth } = context
      const expired = $auth.strategy.token.status().expired()
      if (expired) {
        await $auth.refreshTokens()
        config.headers = {
          Accept: 'application/vnd.api+json',
          'Content-Type': 'application/vnd.api+json',
          Authorization: $auth.strategy.token.get(),
        }
        return config
      } else {
        return config
      }
    },
    (error) => Promise.reject(error)
  )
  $jsonApi.interceptors.response.use(
    async (response) => {
      const { status } = response
      const { $auth } = context
      if (status === RESPONSE_STATUS_UNAUTHORIZED) {
        await $auth.refreshTokens()
      }
      return response
    },
    (error) => {
      $sentry.captureException(error)
      const { $auth } = context
      const expired = $auth.strategy.token.status().expired()
      if (expired) {
        $auth.refreshTokens()
      }
      return Promise.reject(error)
    }
  )
}
