import actions from './actions'
import mutations from './mutations'

const category = {
  state: {
    rootEntities: [],
    entitiesByParentId: {},
    entitiesById: {},
  },
  mutations,
  actions,
  getters: {
    getEntities(state) {
      return (parentId) => {
        if (parentId === null) {
          return state.rootEntities
        }
        return state.entitiesByParentId[parentId] ?? []
      }
    },
    getEntitiesById(state) {
      return (id) => {
        return state.entitiesById[id] ?? null
      }
    },
    hasEntities(state) {
      return (parentId) => {
        if (parentId === null) {
          return !!state.rootEntities.length
        }
        return !!state.entitiesByParentId[parentId]?.length
      }
    },
  },

  namespaced: true,
}
/* */
export default category
