import actions from './actions'
import mutations from './mutations'
import { isObjectNotUndefinedHasProps } from '~/ZenOne-StoryBook/helpers/object'
import { getLocalStorage } from '~/ZenOne-StoryBook/helpers/localStorage'

const getUser = (state) => {
  let user = state?.user ?? {}
  if (!isObjectNotUndefinedHasProps(user)) {
    user = getLocalStorage('user') ?? {}
  }
  return user
}

const user = {
  state: {
    user: localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user'))
      : {},
  },
  getters: {
    user: (state) => getUser(state),
    userId: (state, getters) => getters.user?.id ?? null,
    hasUncompletedTasks: (state, getters) =>
      getters.user?.hasUncompletedTasks ?? false,
  },
  mutations,
  actions,
  namespaced: true,
}

export default user
