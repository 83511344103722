import _ from 'lodash'

const catalogLibrary = {
  state: {
    selectedProductsId: [],
    selectedProductsModelId: [],
    selectedFilterOnlyWithValuesModifierId: [],
    selectedFilterHasNotSpecifiedModifierId: [],
    selectedFilterWithoutValuesModifierId: [],
    clearFilters: false,
  },

  mutations: {
    setSelectedFilterWithoutValuesModifierId: (state, value) => {
      if (value.length === 0) {
        state.selectedFilterWithoutValuesModifierId = []
      }

      if (value.value) {
        state.selectedFilterWithoutValuesModifierId.push(value)
      } else {
        state.selectedFilterWithoutValuesModifierId = _.filter(
          state.selectedFilterWithoutValuesModifierId,
          (el) => el.modifierId !== value.modifierId
        )
      }
    },
    setSelectedFilterOnlyWithValuesModifierId: (state, value) => {
      if (value.length === 0) {
        state.selectedFilterOnlyWithValuesModifierId = []
      }

      if (value.value) {
        state.selectedFilterOnlyWithValuesModifierId.push(value)
      } else {
        state.selectedFilterOnlyWithValuesModifierId = _.filter(
          state.selectedFilterOnlyWithValuesModifierId,
          (el) => el.modifierId !== value.modifierId
        )
      }
    },
    setSelectedFilterHasNotSpecifiedModifierId: (state, value) => {
      if (value.length === 0) {
        state.selectedFilterHasNotSpecifiedModifierId = []
      }

      if (value.value) {
        state.selectedFilterHasNotSpecifiedModifierId.push(value)
      } else {
        state.selectedFilterHasNotSpecifiedModifierId = _.filter(
          state.selectedFilterHasNotSpecifiedModifierId,
          (el) => el.modifierId !== value.modifierId
        )
      }
    },
    setSelectedProductsIdCatalogLibrary: (state, value) => {
      state.selectedProductsId = value
    },
    setSelectedProductsModelIdCatalogLibrary: (state, value) => {
      state.selectedProductsModelId = value
    },
    setClearFiltersCatalogLibrary: (state, value) => {
      state.clearFilters = value
    },
  },

  actions: {},

  getters: {
    getSelectedFilterWithoutValuesModifierId: (state) =>
      state.selectedFilterWithoutValuesModifierId,
    getSelectedFilterOnlyWithValuesModifierId: (state) =>
      state.selectedFilterOnlyWithValuesModifierId,
    getSelectedFilterHasNotSpecifiedModifierId: (state) =>
      state.selectedFilterHasNotSpecifiedModifierId,
    getSelectedProductsIdCatalogLibrary: (state) => state.selectedProductsId,
    getSelectedProductsModelIdCatalogLibrary: (state) =>
      state.selectedProductsModelId,
    getClearFiltersCatalogLibraryCatalogLibrary: (state) => state.clearFilters,
  },

  namespaced: true,
}

export default catalogLibrary
