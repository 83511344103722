import Vue from 'vue'
import Hotjar from 'vue-hotjar'

if (
  process.env.NODE_ENV === 'production' &&
  window.location.hostname === 'admin.zenone.com'
) {
  Vue.use(Hotjar, {
    id: '2809176',
  })
}
