//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IconUser from '../SvgIcons/IconUser'
import IconLogo from '../SvgIcons/IconLogo'
import LeftMenuItem from './LeftMenuItem'
import GitCurrentReleaseTag from '~/ZenOne-StoryBook/components/GitCurrentReleaseTag'
export default {
  name: 'LeftMenu',
  components: { GitCurrentReleaseTag, IconLogo, IconUser, LeftMenuItem },
  props: {
    items: {
      type: Array,
      default: null,
    },
    largeIcons: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    logOut() {
      this.$emit('logOut')
    },
    editProfile() {
      this.$emit('editProfile')
    },
  },
}
