import * as types from './mutation-types'

export default {
  [types.SET_DISTRIBUTORS_LIGHT](state, payload = []) {
    state.distributorsLight = payload
  },
  [types.SET_ROOT_CATEGORIES_LIGHT](state, payload = []) {
    state.rootCategoriesLight = payload
  },
  [types.SET_PRACTICE_LOCATIONS__LIGHT](state, payload = []) {
    state.practiceLocationsLight = payload
  },
}
