//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import Notifications from '~/ZenOne-StoryBook/components/Notifications'
import Alert from '~/ZenOne-StoryBook/components/alert'
import LeftMenu from '~/ZenOne-StoryBook/components/LeftMenu'
import FullScreenLoader from '~/ZenOne-StoryBook/components/full-screen-loader'
import { items as leftMenuItems } from '~/menus/left-menu'

export default {
  name: 'ListLayout',
  components: {
    Alert,
    LeftMenu,
    Notifications,
    FullScreenLoader,
  },
  data() {
    return {
      snackbar: false,
      leftMenuItems,
    }
  },
  computed: {
    ...mapGetters({
      alert: 'ui/alert',
      snackbarText: 'ui/snackbar',
    }),
  },
  watch: {
    snackbarText: {
      handler(text) {
        if (text?.length > 0) {
          this.snackbar = true
        }
      },
    },
  },
  methods: {
    snackbarInputHandler(value) {
      if (!value) {
        this.snackbar = false
        this.$store.commit('ui/SHOW_SNACKBAR', { text: '' })
      }
    },
    async logOut() {
      await this.$auth.logout('refresh')
      await this.$router.replace({ path: '/login' })
      localStorage.clear()
      this.$router.go(null)
    },
    closeAlert() {
      this.$store.commit('ui/HIDE_ALERT')
    },
  },
}
