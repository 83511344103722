import Vuex from 'vuex'
import { isNull } from 'lodash'
import ui from './modules/ui'
import socket from './modules/socket'
import user from './modules/user'
import manufacturer from './modules/manufacturer'
import attributesSettings from './modules/attributesSettings'
import catalogLibrary from './modules/catalogLibrary'
import manufacturersLibrary from './modules/manufacturersLibrary'
import category from './modules/category'
import resources from './modules/resources'

const modules = {
  ui,
  user,
  socket,
  manufacturer,
  attributesSettings,
  catalogLibrary,
  manufacturersLibrary,
  category,
  resources,
}

const store = new Vuex.Store({
  namespaced: true,
  modules,
  strict: process.env.NODE_ENV !== 'production',
})

store.watch(
  (state) => state?.auth?.user,
  async (user) => {
    if (isNull(user)) return false
    await store.dispatch('user/setUser', user)
  }
)

const createStore = () => {
  return store
}

export { store }
export default createStore
