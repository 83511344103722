import actions from './actions'
import mutations from './mutations'

const manufacturer = {
  state: {
    manufacturers: [],
  },
  getters: {
    manufacturers: (state) => state.manufacturers,
  },
  mutations,
  actions,
  namespaced: true,
}

export default manufacturer
