import _ from 'lodash'
import { API as api } from '~/ZenOne-StoryBook/config/api'

const attributesSettings = {
  state: {
    generic: null,
    closeDialog: false,
    modifierSelected: null,
    valuesSelected: [],
    showBtnSaveFooter: true,
    step: 1,
    excludeModifiersId: [],
    excludeModifiersValueId: [],
  },

  mutations: {
    setModifierSelected: (state, value) => {
      state.modifierSelected = value
    },
    setValuesSelected: (state, value) => {
      state.valuesSelected = value
    },
    setShowBtnSaveFooter: (state, value) => {
      state.showBtnSaveFooter = value
    },
    setStep: (state, value) => {
      state.step = value
    },
    setGeneric: (state, value) => {
      state.generic = value
    },
    setExcludeModifiersId: (state, value) => {
      state.excludeModifiersId = value
    },
    setExcludeModifiersValueId: (state, value) => {
      state.excludeModifiersValueId = value
    },
    setCloseDialog: (state, value) => {
      state.closeDialog = value
    },
  },

  actions: {
    async onSaveAttributes({ commit, state, dispatch }) {
      if (state.step === 2) {
        await dispatch('onSaveAttributesStep1')
      }
    },
    async onSaveAttributesStep1({ commit, state, dispatch }) {
      try {
        const genericId = state?.generic?.id

        const modifiersIdAll = [...state.excludeModifiersId]

        modifiersIdAll.push(state.modifierSelected.id)

        const fetchModifiersIdAll = _.map(modifiersIdAll, (el) => {
          return {
            type: 'modifiers',
            id: `${el}`,
          }
        })

        await this.$axios.patch(
          `${api.CATEGORIES}/${genericId}/relationships/${api.MODIFIERS}`,
          {
            data: fetchModifiersIdAll,
          },
          {
            headers: {
              Accept: 'application/vnd.api+json',
              'Content-Type': 'application/vnd.api+json',
            },
          }
        )
        await dispatch('onSaveAttributesStep2')
      } catch (e) {
        console.error('onSaveAttributesStep1', e)
      }
    },

    async onSaveAttributesStep2({ commit, state }) {
      try {
        const genericId = state?.generic?.id

        const modifiersValuesIdAll = [
          ...state.excludeModifiersValueId,
          ...state.valuesSelected,
        ]

        const fetchModifiersValuesIdAll = _.map(modifiersValuesIdAll, (el) => {
          return {
            type: 'modifier-values',
            id: `${el}`,
          }
        })

        await this.$axios.patch(
          `${api.CATEGORIES}/${genericId}/relationships/${api.MODIFIER_VALUES}`,
          {
            data: fetchModifiersValuesIdAll,
          },
          {
            headers: {
              Accept: 'application/vnd.api+json',
              'Content-Type': 'application/vnd.api+json',
            },
          }
        )

        await commit('setCloseDialog', true)
      } catch (e) {
        console.error('onSaveAttributesStep2', e)
      }
    },
  },

  getters: {
    getModifierSelected: (state) => state.modifierSelected,
    getValuesSelected: (state) => state.valuesSelected,
    getShowBtnSaveFooter: (state) => state.showBtnSaveFooter,
    getStep: (state) => state.step,
    getGeneric: (state) => state.generic,
    getExcludeModifiersId: (state) => state.excludeModifiersId,
    getExcludeModifiersValueId: (state) => state.excludeModifiersValueId,
    getCloseDialog: (state) => state.closeDialog,
  },

  namespaced: true,
}

export default attributesSettings
