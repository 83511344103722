import actions from './actions'
import mutations from './mutations'
import { SUCCESS } from '~/ZenOne-StoryBook/helpers/ui'
const ui = {
  state: {
    showFullScreenLoader: false,
    notifications: [],
    alert: {
      show: false,
      content: '',
      type: SUCCESS,
    },
    snackbar: '',
  },
  getters: {
    showFullScreenLoader: (state) => state.showFullScreenLoader,
    alert: (state) => state.alert,
    notifications: (state) => state.notifications,
    snackbar: (state) => state.snackbar,
  },
  mutations,
  actions,
  namespaced: true,
}

export default ui
